
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { DeleteIcon } from '@/components/icons'
import { AlertType, DocumentScope } from '@/enums'

export default defineComponent({
  name: 'DeleteDocumentDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, DeleteIcon
  },

  props: {
    documentId: { type: String, required: true },
    title: { type: String, required: true },
    scope: {
      type: Number as () => DocumentScope,
      required: true,
      default: DocumentScope.workingSiteDocument
    },

    workingSiteId: { type: String, required: false },
    userId: { type: String, required: false },
    communicationId: { type: String, required: false }
  },

  data: function () {
    return {
      loading: false
    }
  },

  methods: {
    deleteDocument: async function () {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'documents/deleteDocument',
          {
            scope: this.scope,
            workingSiteId: this.workingSiteId,
            communicationId: this.communicationId,
            userId: this.userId,
            documentId: this.documentId
          }
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'eliminazione documento fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'eliminazione documento riuscita',
          type: AlertType.success
        })

        this.$emit('close')
      } catch (error) {
        console.error('error deleting the document from the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'eliminazione documento fallita',
          type: AlertType.error
        })
      }
    }
  }
})
