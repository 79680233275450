
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuButton',
  props: {
    to: String,
    name: String
  }
})
