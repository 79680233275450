
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { SuccessIcon } from '@/components/icons'

export default defineComponent({
  name: 'DownloadReportDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, SuccessIcon
  }

})
