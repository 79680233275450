
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalDialog',

  props: {
    type: String
  }

})
