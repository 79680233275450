
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components'

export default defineComponent({
  name: 'CustomCalendarViewHeader',

  components: {
    PrimaryButton
  },

  emit: ['update:displayPeriodUom'],

  props: {
    headerProps: {
      type: Object,
      required: true
    },
    displayPeriodUom: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      periodButtons: [
        { id: 'month', name: 'Mese' },
        { id: 'week', name: 'Settimana' }
      ]
    }
  },

  emits: ['input'],

  methods: {
    onInput (d: Date) {
      this.$emit('input', d)
    },
    switchPeriodUom (uom: string) {
      console.log('period UOM changed ' + uom)
      this.$emit('update:displayPeriodUom' as any, uom)
    }
  }
})
