
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Toggle',

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Boolean,
      default: false,
      required: true
    }
  }
})
