import { helpers } from '@vuelidate/validators'

export const dateValidator: (value: Date) => boolean = (value: Date): boolean => {
  if (!helpers.req(value)) { return true }

  // eslint-disable-next-line eqeqeq
  if (value == undefined || typeof value !== 'object') {
    return false
  }

  return value instanceof Date
}
