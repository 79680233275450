
import { defineComponent } from 'vue'

export interface IAccordionRow {
  id: string;
  text: string;
}

export default defineComponent({
  name: 'Accordion',

  props: {
    rows: []
  }
})
