import store from '@/store'
import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const globalAuthGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  console.debug('globalAuthGuard', { from, to })

  if (to.name === 'Login') {
    next()
  } else {
    const isAuthenticated = await store.getters['auth/isAuthenticated']
    if (!isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next({ name: 'Login', query: { redirect: to.fullPath } } as any)
    } else {
      next()
    }
  }
}
