
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Checkbox',

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Boolean,
      default: false,
      required: false
    }
  }
})
