
import { defineComponent } from 'vue'
import { BlueDocumentIcon } from '@/components/icons'

export default defineComponent({
  name: 'UploadedDocumentPreview',

  components: {
    BlueDocumentIcon
  },

  emits: ['delte'],

  props: {
    name: String,
    type: String,
    hideDeleteButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    mimeTypeSlag: function () {
      switch (this.type) {
        case 'image/jpg':
        case 'image/jpeg': return 'JPG'
        case 'application/pdf': return 'PDF'
        case 'image/png': return 'PNG'
        default: return 'FILE'
      }
    }
  }
})
