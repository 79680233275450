
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ClientIconSmall',

  props: {
    mimeTypeSlag: String
  }
})
