import moment from 'moment'

export const humanizedClockingInTiming = (timing?: number): string => {
  // eslint-disable-next-line eqeqeq
  if (timing == undefined || typeof timing !== 'number') { return '-' }
  const duration = moment.duration(timing, 'seconds')

  let humanized = ''

  if (duration.asDays() >= 1) {
    humanized += `${Math.round(duration.asDays())} Giorni`
  }

  humanized += ` ${duration.hours()} Ore ${duration.minutes()} Minuti`

  return humanized
}

export const durationHumanized = (duration?: number, format: 'seconds' | 'minutes' | 'hours' = 'seconds'): string => {
  let str = '-'
  if (duration !== undefined) {
    const momentD = moment.duration(duration, format)
    str = `${Math.trunc(momentD.asHours()).toLocaleString().padStart(2, '0')}:${momentD.minutes().toLocaleString().padStart(2, '0')}:${momentD.seconds().toLocaleString().padStart(2, '0')}`
  }

  return str
}

export const parseHoursAndMinutesToTimingSeconds = (hoursAndMinutes: string): number | undefined => {
  if (hoursAndMinutes.length < 3 || !hoursAndMinutes.includes(':')) { return undefined }

  const timingArray = hoursAndMinutes.split(':')

  if (
    timingArray.length !== 2
    || timingArray[1].length !== 2
    || timingArray[0].length < 1
    || Number.isNaN(Number(timingArray[0]))
    || Number.isNaN(Number(timingArray[1]))
  ) {
    return undefined
  }

  return (parseInt(timingArray[0], 10) * 60 * 60) + (parseInt(timingArray[1], 10) * 60)
}
