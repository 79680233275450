import * as moment from 'moment'

function round (this: moment.Moment, precision: number, rawKey: string, direction: 'round' | 'floor' | 'ceil' = 'round'): moment.Moment {
  const methods = {
    hours: {
      name: 'Hours',
      maxValue: 24
    },
    minutes: {
      name: 'Minutes',
      maxValue: 60
    },
    seconds: {
      name: 'Seconds',
      maxValue: 60
    },
    milliseconds: {
      name: 'Milliseconds',
      maxValue: 1000
    }
  }

  const keys = {
    mm: methods.milliseconds.name,
    milliseconds: methods.milliseconds.name,
    Milliseconds: methods.milliseconds.name,
    s: methods.seconds.name,
    seconds: methods.seconds.name,
    Seconds: methods.seconds.name,
    m: methods.minutes.name,
    minutes: methods.minutes.name,
    Minutes: methods.minutes.name,
    H: methods.hours.name,
    h: methods.hours.name,
    hours: methods.hours.name,
    Hours: methods.hours.name
  }

  let maxValue: any
  let value = 0
  let rounded = false
  let subRatio = 1

  const needsPluralized = (k: string): boolean => k.length > 1 && k !== 'mm' && k.slice(-1) !== 's'
  const key: any = (keys as any)[needsPluralized(rawKey) ? `${rawKey}s` : rawKey].toLowerCase()

  if (!(methods as any)[key]) {
    throw new Error(`Invalid method ${key}. Try one of: ${Object.keys(methods).join()}`)
  }

  const get = `get${(methods as any)[key].name}`
  const set = `set${(methods as any)[key].name}`

  Object.keys(methods).forEach((k) => {
    if (k === key) {
      value = (this as any)._d[get]()
      maxValue = (methods as any)[k].maxValue
      rounded = true
    } else if (rounded) {
      subRatio *= (methods as any)[k].maxValue
      value += (this as any)._d[`get${(methods as any)[k].name}`]() / subRatio;
      (this as any)._d[`set${(methods as any)[k].name}`](0)
    }
  })

  value = (Math as any)[direction](value / precision) * precision
  value = Math.min(value, maxValue);
  (this as any)._d[set](value)

  return this as moment.Moment
}

function ceil (this: moment.Moment, precision: number, key: string): moment.Moment {
  return this.round(precision, key, 'ceil')
}

function floor (this: moment.Moment, precision: number, key: string): moment.Moment {
  return this.round(precision, key, 'floor')
}

declare module 'moment' {
  interface Moment {
    round(precision: number, rawKey: string, direction?: string): moment.Moment;
    ceil(precision: number, rawKey: string): moment.Moment;
    floor(precision: number, rawKey: string): moment.Moment;
  }
}

(moment as any).fn.round = round;
(moment as any).fn.ceil = ceil;
(moment as any).fn.floor = floor
