
import { defineComponent } from 'vue'
import moment from 'moment'
import { EllipseDecoration, ClientIconSmall, DocumentIconSmall } from '@/components'

export default defineComponent({
  name: 'WorkingSiteCard',

  components: {
    EllipseDecoration, ClientIconSmall, DocumentIconSmall
  },

  created: function () {
    (this as any).moment = moment
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  }
})
