
import moment from 'moment'
import { defineComponent } from 'vue'
import { IUsersStoreState } from '@/interfaces'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('users')

export default defineComponent({
  name: 'UserClockingIn',

  created: function () {
    (this as any).moment = moment
  },

  computed: {
    ...mapState<IUsersStoreState>({
      employee: (state: IUsersStoreState) => state.user
    })
  },

  props: {
    modelValue: { type: Object, required: true }
  }
})
