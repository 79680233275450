import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68350284"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "related-single-document" }
const _hoisted_2 = {
  class: "edit-and-delete",
  hidden: "!hideDeleteButton"
}
const _hoisted_3 = { class: "document flex" }
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = { class: "font-bold text-left name text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlueDocumentIcon = _resolveComponent("BlueDocumentIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", {
        class: "block float-right delete-button font-bold",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete')))
      }, "Elimina")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BlueDocumentIcon, { mimeTypeSlag: _ctx.mimeTypeSlag }, null, 8, ["mimeTypeSlag"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.name), 1)
      ])
    ])
  ]))
}