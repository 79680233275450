
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Loader',

  props: {
    message: String
  }
})
